/*jshint esversion: 6 */
import global from '../../global.js';
import storage from '../../storage.js';

function list(success){
  console.log("list");

  if(!global.is_cordova()){
    var devices=[
      {
        name:"Klean",
        id: "00:21:3E:92:87:05",
        address: "00:21:3E:92:87:05"
      },
    ];
    success(devices);
  }
  else{
    // bluetoothSerial.list(function(devices) {
      success(devices);
    // });
  }
}


//funzione principale di analisi della connessione
export function bluetooth(){
  global.app.dialog.progress("Ricerca del bluetooth in corso..");

  var html='';
  html += `
  <div id="bluetooth">
    <div class="block-title">Dispositivi non accoppiati</div>
    <div class="unpaired list">
      <ul>
      </ul>
    </div>         
  </div>`;
  html+='<div id="readonly" style="margin: 15px;"></div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var devices=[];
  
  ble.startScan([], function(device) {
    devices.push(device);
		console.log(JSON.stringify(device));
  }, function (error) {
    console.log("Scanning failed: " + error);
  });
  setTimeout(function(){
    console.log("stop scan");
    ble.stopScan(
      function() {

        // close dialog
        global.app.dialog.close();
        
        console.log("stopScan success");
        jQuery('.unpaired ul').html('');
        jQuery.each(devices, function(index, device) {
          var html='';

          html+='<li class="bluetooth_device" mac_address="'+device.id+'">';
          html+='<div class="item-content">';

            html+='<div class="item-inner">';
              var mac_address=device.id;
              var label=device.id;
              var className="";
              if (device.name) {
                label=device.name;
                if(label.startsWith('RS420')){
                  className="lettore";
                  label+=" (Lettore Chip)";
                }
              }
              if(device.address=="4C:E0:DB:8B:FD:DD"){
                console.log("device trovato");
                className="lettore_bolo";
                label+=" (Cell Iride)";
              }

              html+='<div class="item-title '+className+'" id="'+mac_address+'">'+label+'</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        jQuery('.unpaired ul').append(html);
        });
      },
      function() {
        console.log("stopScan failed");
      }
    );
  }, 5000);

  jQuery(document).on('click', '.bluetooth_device', function(){
    
    var mac_address=jQuery(this).attr('mac_address');
    console.log("mac_address: "+mac_address);
    var label=jQuery(this).find('.item-title').html();
    console.log("label: "+label);
    
    ble.connect(mac_address, function(peripheral) {
      console.log("connected");
      console.log(JSON.stringify(peripheral));

       device_connected(mac_address);

    }, function() {
      console.log("not connected");
    });
  });

}


//la funzione
function device_connected(mac_address){
  console.log("10. connected");

  global.app.ptr.destroy('.ptr-content');
  global.app.dialog.close();

  pageBluetoothConnected(mac_address);

}

function pageBluetoothConnected(mac_address){

  var html='';
  html+='<div class="block-title">L\'app è connessa in maniera corretta con il bluethoot.</div>';
  html+='<div class="block block-strong">Adesso puoi iniziare ad usare il dispositivo per leggere.';
  html+='</div>';
  html+="<button class='button button-fill color-red' id='bt_disconnect'>Disconnettiti</button>";

  // textarea da poter inviare
  html += `<div class="list no-hairlines-md">
            <ul>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Messaggio da inviare</div>
                  <div class="item-input-wrap">
                    <input id="toSend" type="text" placeholder="Messaggio" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="item-content">
                <div class="item-inner">
                  <button class='button button-fill color-green' id='send_serial'>Invia</button>
                </div>
              </li>
            </ul>
          </div>`;
            


  jQuery('#bluetooth').html(html);

  jQuery('#ricevi_serial').click(function(){
    readBluetooth(mac_address);
  });

  jQuery('#send_serial').click(function(){
    var text=jQuery('#toSend').val();
    if(global.is_cordova()){
      
      // var data = new Uint8Array(1);
			// data[0] = 0x31;

      ble.writeWithoutResponse(mac_address, '0000abf0-0000-1000-8000-00805f9b34fb','0000abf1-0000-1000-8000-00805f9b34fb', stringToBytes(text), function(){
        console.log("scritto");
        jQuery('#toSend').val('');
        jQuery('#readonly').append('<br><span style="float:right;">'+text+'</span>');

      }, function(error){
        console.log("non scritto");
        console.log(error);
      });  
    }
    else{
      jQuery('#readonly').append('<br><span style="float:right;">'+text+'</span>');
      jQuery('#toSend').val('');

      // alert("Attenzione errore nell'app -  manca il modulo BT");
    }
  });

  

  //attiva la lettura

  // readBluetooth(mac_address);
  var onData = function (buffer) {
		// Decode the ArrayBuffer into a typed Array based on the data you expect
		// var data = new Uint8Array(buffer);

    jQuery('#readonly').append('<br><span style="float:left;">'+bytesToString(buffer).substring(1)+'</span>');
    
		// alert('Button state changed to ' + bytesToString(buffer));
	};

	ble.startNotification(mac_address, '0000abf0-0000-1000-8000-00805f9b34fb', '0000abf2-0000-1000-8000-00805f9b34fb', onData, 
    function(error){
      console.log("non scritto");
      console.log(error);
    });

  // Quando clicco su un device già connesso, effettuo la disconnessione
  jQuery("#bt_disconnect").click(function() {
    global.app.dialog.progress("Disconnessione in corso..");

    disconnectDevice();
  });

}

function bytesToString(buffer) {
	return String.fromCharCode.apply(null, new Uint8Array(buffer));
}

function readBluetooth(mac_address){
  console.log("Leggo bluetooth");
}

function stringToBytes(string) {
	var array = new Uint8Array(string.length);
	for (var i = 0, l = string.length; i < l; i++) {
		array[i] = string.charCodeAt(i);
	}
	return array.buffer;
}