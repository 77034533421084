
/*jshint esversion: 6 */

import init_app_plus from '../../init_plus.js';
import global from '../../global.js';
import * as theme from '../../themes/f7/f7.js';
import * as project from './index.js';

export function init_app(){
  init_app_plus(theme, project);
}

init_app();
