/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import './style.css';

import * as bluetooth_new from './ble.js';

global.nomeApp='OliveRec';
global.language='it';
global.enablePWA=true;

global.useSocialLogin=false;
global.skip_classic_login=true;
global.use_oauth2={
  "label": "OliveRec Auth",
  "client_id": "oliverec",
  "client_secret": "zrYFkATfAcrJ6kXnzq8udOtyXK1Y3V3O",
  "redirect_uri": location.origin,
  "endpoint": "https://login.aedit.it/realms/oliverec/protocol/openid-connect/"  
}

global.app_version='1.0.01';

global.base_link='http://localhost:8080/';
global.base_path='http://localhost:8888/';
if (location.origin!='http://localhost:8080') {
  global.base_link='https://olive-rec-app.aedit.it/';
  global.base_path='https://olive-rec.aedit.it/';
}
global.chat_url=global.base_path;

global.base_call=global.base_path+'api/v1/oliverec/';
global.base_call_aedita=global.base_path;
global.nomeApp='OliveRec';
global.language='it';
global.settingsName='oliverec';


//Hook obbligatorio per definire la home progetto
// 
export function home_page(){
  var s=storage.get();
  
  if (s.user) {
      global.theme.render({
        exclude_card:false,
        title: ``,
        content: "<div id='banner'>Clicca sulle tre lineette in alto a sinistra e seleziona Bluetooth. Questa è una pagina di prova.></div>",
      });
    
    }
  else {
    location.hash='login';
  }
}



//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  console.log("ccc");
  jQuery('#footer').html('');

  router.on({
    'bluetooth_new':function(){
      jQuery('#download_app').hide();

      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      bluetooth_new.bluetooth();
    },
     '*': function (param) {
      // cleanPage();
      home_page();
    },
  });

  return router;
}


export function get_theme_param(theme_param){
  var s=storage.get();

  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Bluetooth', 'icon_f7':'antenna_radiowaves_left_right', 'href':'#bluetooth_new'},
  ];


  theme_param.sidebars=sidebars;
  theme_param.title='<img src="icons/oliverec.svg" height="40" />';;
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}
